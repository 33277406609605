import React, {useContext, useEffect, useState} from "react";
import UserAppBar from "../user/UserAppBar";
import UserContext from "../context/UserContext";
import {useParams} from 'react-router-dom';
import {addMessageToRoomId, getMessagesForRoomId} from "../service/MessageEndpointService";
import MessageInput from "../commons/MessageInput";
import ChatStream from "./ChatStream";
import styled from 'styled-components/macro';


export default function Chat() {

    const { userData, token, tokenIsValid } = useContext(UserContext);
    const [messageToSend, setMessageToSend] = useState("");
    const [messages, setMessages] = useState([]);
    const { roomId } = useParams();

    const Scroll = require('react-scroll');
    const scroll = Scroll.animateScroll;

    useEffect(() => {tokenIsValid() && getMessagesForRoomId(roomId, token).then(setMessages)}
        ,[token, tokenIsValid, roomId]);

    useEffect(() => {
        scroll.scrollToBottom()
    },[scroll]);

    return <>
        <UserAppBar />
        <ChatRoom>
            <ChatStream messages={messages} userData={userData}/>
            <MessageInputFooter>
                <MessageInput placeholder='Send Message' onSend={onSend}
                          handleChange={(event) => setMessageToSend(event.target.value)}
                          value={messageToSend}
                />
            </MessageInputFooter>
        </ChatRoom>
        </>

   function onSend(event) {
       event.preventDefault();
       const lastMessage = messages[messages.length -1];
       const lastRecipient = lastMessage.recipientId;
       const lastSender = lastMessage.senderId;
       if(lastRecipient === userData.sub) {
            addMessageToRoomId(lastMessage.advertId, lastSender, messageToSend, token)
                .then(setMessages);
           setMessageToSend("");
           scroll.scrollToBottom();
        } else {
           addMessageToRoomId(lastMessage.advertId, lastRecipient, messageToSend, token)
               .then(setMessages);
           setMessageToSend("");
           scroll.scrollToBottom();
       }
    }

}

const ChatRoom = styled.div`
  display: grid;
  grid-template-rows: 1fr min-content;
`

const MessageInputFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`