import React, {useContext, useState} from "react";
import UserAppBar from "../user/UserAppBar";
import Searchbar from "../commons/Searchbar";
import styled from "styled-components/macro";
import useLoadRoomsForUser from "../hooks/useLoadRoomsForUser";
import MessageRoomItems from "./MessageRoomItems";
import UserContext from "../context/UserContext";
import AdvertsContext from "../context/AdvertsContext";

export default function MessageDashboard() {

    const [searchedTerm, setSearchedTerm] = useState('');
    const [messageRoomList] = useLoadRoomsForUser();
    const { userData } = useContext(UserContext);
    const { advertData } = useContext(AdvertsContext);

    return <>
        <UserAppBar />
        <MessageDashboardContainer>
            <MessageRoomItems messageRoomList={messageRoomList}
                              userData={userData}
                              advertData={advertData}
            />
        </MessageDashboardContainer>
        </>
}

const MessageDashboardContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  padding-top: 70px;
  padding-left: var(--size-m);
  padding-right: var(--size-m);
`
