import { createGlobalStyle} from "styled-components";

export default createGlobalStyle`
  :root {
  
  --size-xs: 5px;
  --size-s: 10px;
  --size-m: 15px;
  --size-l: 25px;
  --size-xl: 35px;
  --size-xxl: 50px;
  
  --primary-100: #508991;
  --primary-50: #7BB0B7;
  --primary-10: #B0CFD4;
  
  --secondary-100: #D2D8B3;
  --secondary-50: #E7EAD7;
  --secondary-10: #F7F8F2;
  
  --tertiary-100: #272727;
  --tertiary-75: #525252;
  --tertiary-50: #7a7a7a;
  --tertiary-10: #E0E0E0;
  
  --shadow-items: 2px 2px 5px var(--tertiary-50);
  }

`