import React from "react";
import styled from "styled-components/macro";
import {RiSearchLine} from 'react-icons/ri';

const Searchbar = (props) => {
    return <SearchContainer>
        <SearchIcon />
        <Input
            placeholder={props.placeholder}
            onChange={props.handleChange}
            type='search'
        />
        </SearchContainer>;
}

const SearchContainer = styled.div`
  display: inline-flex;
  flex: 1;
  border-radius: 200px;
  overflow: hidden;
  background-color: white;
`
const SearchIcon = styled(RiSearchLine)`
  padding: 0.6rem;
`

const Input = styled.input`
  border: 0;
  outline: none;
  flex: 1;
`

export default Searchbar;