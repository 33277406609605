import {useContext, useEffect, useState} from "react";
import UserContext from "../context/UserContext";
import {getRoomsByUser} from "../service/MessageEndpointService";


export default function UseLoadRoomForUser() {

    const [messageRoomList, setMessageRoomList] = useState([]);
    const { userData, token, tokenIsValid } = useContext(UserContext);

    useEffect(() => tokenIsValid() && getRoomsByUser(userData.sub, token).then(setMessageRoomList)
    , [token, tokenIsValid, userData.sub]);

    return [messageRoomList];
}