import React from 'react';
import {Switch, Route, Redirect} from "react-router-dom";
import Home from "./components/home/Home";
import LoginPage from "./components/login/LoginPage";
import UserContextProvider from "./components/context/UserContextProvider";
import UserSecurityRoute from "./components/routing/UserSecurityRoute";
import styled from 'styled-components/macro';
import AdvertsContextProvider from "./components/context/AdvertsContextProvider";
import UserDashboard from "./components/user/UserDashboard";
import AdvertDetailsPage from "./components/adverts/AdvertDetailsPage";
import CreateAdvert from "./components/adverts/CreateAdvert";
import EditAdvert from "./components/adverts/EditAdvert";
import MessageDashboard from "./components/messenger/MessageDasboard";
import Chat from "./components/messenger/Chat";
import ProfileDashboard from "./components/profile/ProfileDashboard";
import ProfileContextProvider from "./components/context/ProfileContextProvider";
import CreateProfile from "./components/profile/CreateProfile";

function App() {

  return (
      <UserContextProvider>
          <ProfileContextProvider>
            <AdvertsContextProvider>
              <PageLayout>
                    <Switch>
                        <Route path='/home' component={Home} />
                        <Route path='/login' component={LoginPage} />
                        <UserSecurityRoute path='/dashboard' component={UserDashboard} />
                        <UserSecurityRoute path='/advert/:id' component={AdvertDetailsPage} />
                        <UserSecurityRoute path='/create' component={CreateAdvert} />
                        <UserSecurityRoute path='/edit/:id' component={EditAdvert} />
                        <UserSecurityRoute path='/message/dashboard' component={MessageDashboard} />
                        <UserSecurityRoute path='/message/:roomId' component={Chat}/>
                        <UserSecurityRoute path='/userprofile' component={ProfileDashboard} />
                        <UserSecurityRoute path='/createprofile' component={CreateProfile} />
                        <Route path='/'>
                            <Redirect to='/home' />
                        </Route>
                    </Switch>
              </PageLayout>
            </AdvertsContextProvider>
          </ProfileContextProvider>
      </UserContextProvider>
  );
}

export default App;

const PageLayout = styled.div`
  height: 100vh;
`
