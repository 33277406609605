import React from "react";
import styled from 'styled-components/macro';

export default function ChatStream({messages, userData}) {


    return <ChatMessages>
        {messages && messages.map((msg) => {
            if(msg.recipientId === userData.sub) {
                return <ReceiveMessages key={msg.messageId}>{msg.message}</ReceiveMessages>;
            } else {
                return <SendMessages key={msg.messageId}>{msg.message}</SendMessages>
            }
            })}
        </ChatMessages>
}


const ChatMessages = styled.ul`
  display: grid;
  grid-auto-rows: min-content;
  gap: var(--size-s);
  padding: 70px var(--size-m) 40px var(--size-m);
  list-style: none;
`

const SendMessages = styled.li`
  justify-self: end;
  padding: var(--size-s);
  background-color: var(--secondary-100);
  border-radius: 18px;
  box-shadow: var(--shadow-items);
`

const ReceiveMessages = styled.li`
  justify-self: start;
  padding: var(--size-s);
  background-color: var(--primary-10);
  border-radius: 18px;
  box-shadow: var(--shadow-items);
`