import React from "react";
import styled from "styled-components/macro";
import {RiMapPin2Fill, RiUser3Line} from "react-icons/ri";
import NumberFormat from "react-number-format";

export default function MessageRoomCard({searchedAdvertId, userData, advertData}) {

    const advert = advertData?.find((ad) => ad.id === searchedAdvertId);


    return advert? <CardItems>
        <Image>
            <CardImage src={advert.image}/>
        </Image>
        <CardContent>
            <HeaderContainer>
                <LocationElement>
                    <RiMapPin2Fill />{advert.location}
                </LocationElement>
                <Names>
                    <RiUser3Line/>{advert.creator}
                </Names>
            </HeaderContainer>
            <MessageHeader>
                {advert.title}
            </MessageHeader>
            <MessageNumberContainer>
                <DetailsSpan>
                    Amount:
                    {advert.amount}
                </DetailsSpan>
                <DetailsSpan>
                    <NumberFormat
                        value={advert.price}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'€'}
                    />
                </DetailsSpan>
            </MessageNumberContainer>
        </CardContent>
        </CardItems> : <> </>;
}

const CardItems = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
`

const Image = styled.div`
  max-width: 100px;
  min-width: 100px;
  max-height: 100px;
`

const CardImage = styled.img`
  object-fit: cover;
  align-self: start;
  max-height: 100%;
  width: auto;
`

const CardContent = styled.div`
  background-color: var(--primary-10);
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const LocationElement = styled.p`
  color: var(--tertiary-75);
  font-size: 0.9em;
  margin: 0;
  padding: var(--size-xs);
`

const MessageHeader = styled.h3`
  color: var(--tertiary-100);
  font-size: 1.2em;
  margin: 0;
  padding: var(--size-xs);
`

const MessageNumberContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: var(--size-s);
`

const DetailsSpan = styled.span`
  color: var(--tertiary-75);
  margin: 0;
  padding: var(--size-xs);
  justify-self: center;
  justify-content: space-between;
`

const Names = styled.span`
  color: var(--tertiary-50);
  font-size: 0.9em;
  margin: 0;
  padding: var(--size-xs);
`