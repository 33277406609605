import React from 'react';
import styled from 'styled-components/macro';
import {NavLink} from 'react-router-dom';
import {
    RiAddCircleLine,
    RiMailLine,
    RiUserLine,
} from "react-icons/ri";
import {useHistory} from "react-router";


export default function UserAppBar() {

    const history = useHistory();

    return <HeaderStyled>
            <ImageStyled src='/rubble_logo.png' alt='rubble logo' onClick={() => history.push('/dashboard')}/>
            <NavigationGroupStyled>
                <NavLinkStyled to='/create'><RiAddCircleLine/></NavLinkStyled>
                <NavLinkStyled to='/message/dashboard'><RiMailLine/></NavLinkStyled>
                <NavLinkStyled to='/userprofile'><RiUserLine/></NavLinkStyled>
            </NavigationGroupStyled>
        </HeaderStyled>;

}

const HeaderStyled = styled.div`
  position: fixed;
  width: 100%;
  z-index: 1;
  
  display: grid;
  grid-template-columns: 1fr min-content min-content;
  
  background-color: var(--primary-50);
  
  h2 {
  padding-left: var(--size-s);
  }
`

const ImageStyled = styled.img`
  max-height: 70px;
  padding-left: var(--size-xs);
`

const NavigationGroupStyled = styled.nav`
  display: grid;
  grid-template-columns: min-content min-content min-content min-content;
  column-gap: var(--size-l);
  justify-self: center;
`

const NavLinkStyled = styled(NavLink)`
  text-decoration: none;
  font-size: var(--size-l);
  color: var(--tertiary-100);
  place-self: center stretch;
`