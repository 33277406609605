import React, {useState} from "react";
import styled from "styled-components/macro";
import NumberFormat from "react-number-format";
import {useHistory} from 'react-router-dom';
import {RiMapPin2Fill} from "react-icons/ri";


export default function AdvertsListItem({filteredData}) {

    const history = useHistory();


    return <>
        <AdListItem>
            {filteredData?.map((item) => (
            <CardsItems key={item.id}>
                        <ImageContainer>
                            <CardImage src={item.image} />
                        </ImageContainer>
                        <CardContent onClick={() => history.push(`/advert/${item.id}`)} >
                        <LocationElement>
                            <RiMapPin2Fill />{item.location}
                        </LocationElement>
                        <AdvertHeader>
                            {item.title}
                        </AdvertHeader>
                        <AdvertNumberContainer>
                            <AdvertNumber>
                                Amount: {item.amount}
                            </AdvertNumber>
                            <AdvertNumber>
                                <NumberFormat
                                    value={item.price}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'€'}
                                />
                            </AdvertNumber>
                        </AdvertNumberContainer>
                    </CardContent>
            </CardsItems>
        ))}
    </AdListItem>
        </>;

}

const AdListItem = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  gap: var(--size-s);
  padding-bottom: var(--size-m);
`

const CardsItems = styled.li`
  display: grid;
  grid-template-columns: min-content 1fr;
  list-style-type: none;
  box-shadow: var(--shadow-items);
`

const CardContent = styled.div`
  background-color: var(--primary-10);
`

const ImageContainer = styled.div`
  max-width: 100px;
  min-width: 100px;
  max-height: 100px;
`

const CardImage = styled.img`
  object-fit: cover;
  align-self: start;
  max-height: 100%;
  width: auto;
`

const LocationElement = styled.p`
  color: var(--tertiary-75);
  font-size: 0.9em;
  margin: 0;
  padding: var(--size-xs);
`

const AdvertHeader = styled.h3`
  color: var(--tertiary-100);
  font-size: 1.2em;
  margin: 0;
  padding: var(--size-xs);
`
const AdvertNumberContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: var(--size-s);
`

const AdvertNumber = styled.span`
  color: var(--tertiary-75);
  font-size: 0.9em;
  margin: 0;
  padding: var(--size-xs);
`

