import React, {useContext, useEffect, useState} from "react";
import AdvertsContext from "./AdvertsContext";
import UserContext from "./UserContext";
import {
    addNewAdvert,
    getAdvertsForUser,
    getAllAdverts,
    removeAdvert,
    updateAdvert
} from "../service/AdvertsEndpointService";


export default function AdvertsContextProvider({children}) {

    const [advertData, setAdvertData] = useState([]);
    const [userAdvertData, setUserAdvertData] = useState([]);
    const { token, tokenIsValid, userData } = useContext(UserContext);

    useEffect(() => tokenIsValid() && getAllAdverts(token).then(setAdvertData)
        ,[token, tokenIsValid]);

    useEffect(() => tokenIsValid() && getAdvertsForUser(userData.sub, token).then(setUserAdvertData)
    , [token, tokenIsValid])

    const create = (image, title, description, category, amount, weight, price, location, contactInformation) =>
        addNewAdvert(image, title, description, category, amount, weight, price, location, contactInformation, token)
            .then((newAdData) => setAdvertData([newAdData, ...advertData]));

    const update = (id, image, title, description, category, amount, weight, price, location, contactInformation) =>
        updateAdvert(id, image, title, description, category, amount, weight, price, location, contactInformation, token)
        .then((adToUpdate) => setAdvertData([...advertData.filter((ads) => ads.id !== adToUpdate.id), adToUpdate]));

    const remove = (id) => removeAdvert(id, token)
        .then(() => setAdvertData(advertData.filter((ads) => ads.id !== id)));

    return <AdvertsContext.Provider value={{
        advertData,
        userAdvertData,
        create,
        update,
        remove,
    }}>
        {children}
    </AdvertsContext.Provider>
}