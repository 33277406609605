import {useEffect, useState} from "react";
import {axiosClient} from "../service/AxiosClient";

export default function UseHomeService() {

    const [homeData, setHomeData] = useState([]);

    useEffect(() => {
        axiosClient.get('/api/ads').then((response) => setHomeData(response.data))
    }, []);

    return [homeData];
}