import React, {useState} from "react";
import styled from "styled-components/macro";
import {useHistory} from 'react-router-dom';


const initialState = {
    fullName: "",
    email: "",
    phoneNumber: "",
    profilePicture: "",
    currentWorkingLocation: "",
}

export default function ProfileForm({initialForm = initialState, sendSave}) {

    const [profileFormData, setProfileFormData] = useState(initialForm);
    const history = useHistory();

    return <FormPage onSubmit={handleSubmit}>
        <label>
            Full name
            <Input
                name='fullName'
                value={profileFormData.fullName}
                onChange={handleChange}
                type='text'
                required
            />
        </label>
        <label>
            E-mail
            <Input
                name='email'
                value={profileFormData.email}
                onChange={handleChange}
                type='text'
                required
            />
        </label>
        <label>
            Phone number
            <Input
                name='phoneNumber'
                value={profileFormData.phoneNumber}
                onChange={handleChange}
                type='text'
                required
            />
        </label>
        <label>
            Profile picture
            <Input
                name='profilePicture'
                value={profileFormData.profilePicture}
                onChange={handleChange}
                type='text'
                required
            />
        </label>
        <label>
            Working Location
            <Input
                name='currentWorkingLocation'
                value={profileFormData.currentWorkingLocation}
                onChange={handleChange}
                type='text'
                required
            />
        </label>
        <ButtonGroup>
            <Button type='button' onClick={doCancel}>Cancel</Button>
            <Button type='submit'>Save</Button>
        </ButtonGroup>
        </FormPage>

    function handleChange(event) {
        setProfileFormData({ ...profileFormData, [event.target.name]: event.target.value});
    }

    function doCancel() {
        history.goBack();
    }

    function handleSubmit(event) {
        event.preventDefault();
        sendSave(profileFormData)
    }
}

const FormPage = styled.form`
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: var(--size-s);
  padding: 80px var(--size-m) 0 var(--size-m);
  
  label {
    place-self: center stretch;
  }
`

const Input = styled.input`
  width: 98%;
  place-self: center stretch;
`


const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--size-m);
`

const Button = styled.button`
    border: none;
    outline: none;
    background-color: var(--secondary-50);
    font-size: 1.2em;
    font-weight: 600;
`