import React, {useContext} from "react";
import UserAppBar from "../user/UserAppBar";
import AdvertsContext from "../context/AdvertsContext";
import AdvertForm from "../commons/AdvertForm";
import {useHistory} from 'react-router-dom';


export default function CreateAdvert() {

    const history = useHistory();
    const {create} = useContext(AdvertsContext);

    return <>
        <UserAppBar />
        <AdvertForm sendSave={sendSave}/>
        </>;

    function sendSave(formData) {
        const { image, title, description, category, amount, weight, price, location, contactInformation } = formData;
        create(image, title, description, category, amount, weight, price, location, contactInformation);
        history.push('/dashboard');
    }
}