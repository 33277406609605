import React, {useContext} from "react";
import UserAppBar from "../user/UserAppBar";
import AdvertForm from "../commons/AdvertForm";
import AdvertsContext from "../context/AdvertsContext";
import {useHistory, useParams} from "react-router";

export default function EditAdvert() {

    const { advertData, update } = useContext(AdvertsContext);
    const { id } = useParams();
    const history = useHistory();
    const advertToUpdate = advertData.find((advert) => advert.id === id);

    return !advertToUpdate ? null : (<>
            <UserAppBar />
            <AdvertForm sendSave={updateFormData} initialForm={advertToUpdate}/>
        </>
    );

    function updateFormData(formData) {
        const { id, image, title, description, category, amount, weight, price, location, contactInformation } = formData;
        update(id, image, title, description, category, amount, weight, price, location, contactInformation);
        history.goBack();
    }
}