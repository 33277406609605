import React, {useContext, useEffect, useState} from "react";
import UserContext from "./UserContext";
import {getProfileForUser, addUserProfile, updateUserProfile} from "../service/ProfileEndpointService";
import ProfileContext from "./ProfileContext";

export default function ProfileContextProvider({children}) {

    const [profileData, setProfileData] = useState({});
    const { token, tokenIsValid } = useContext(UserContext);

    useEffect(() => {
        tokenIsValid() && getProfileForUser(token).then(setProfileData)
    }, [token, tokenIsValid]);

    const createProfile = (fullName, email, phoneNumber, profilePicture, currentWorkingLocation) =>
        addUserProfile(fullName, email, phoneNumber, profilePicture, currentWorkingLocation, token)
            .then(setProfileData);

    const updateProfile = (fullName, email, phoneNumber, profilePicture, currentWorkingLocation) =>
        updateUserProfile(fullName, email, phoneNumber, profilePicture, currentWorkingLocation, token)
            .then(setProfileData);

    return <ProfileContext.Provider value={{
        profileData,
        createProfile,
        updateProfile
    }}>
        {children}
    </ProfileContext.Provider>
}