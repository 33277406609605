import React from 'react';
import styled from 'styled-components/macro';
import HomeAppBar from "./HomeAppBar";
import WelcomeText from "./WelcomeText";
import HomeScreenAdvertsListItem from "./HomeScreenAdvertsListItem";

export default function Home () {

    return <>
        <HomeAppBar />
        <HomePage>
        <WelcomeText />
        <HomeScreenAdvertsListItem/>
        </HomePage>
        </>;
}

const HomePage = styled.div`
  display: grid;
  grid-template-rows: min-content min-content;
  padding-left: var(--size-m);
  padding-right: var(--size-m);
`






