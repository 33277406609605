import React, {useContext} from "react";
import ProfileContext from "../context/ProfileContext";
import styled from 'styled-components/macro';
import {RiProfileLine} from 'react-icons/ri';
import {useHistory} from 'react-router-dom';


export default function UserProfile() {

    const { profileData } = useContext(ProfileContext);
    const history = useHistory();
    const booleanProfileData = Object.keys(profileData).length === 0 && profileData.constructor === Object;

    return <> {!booleanProfileData ?
        (<ProfileDataContainer>
            <div>
                <p>{profileData.fullName}</p>
                <p>{profileData.profilePicture}</p>
                <p>{profileData.email}</p>
                <p>{profileData.phoneNumber}</p>
                <p>{profileData.currentWorkingLocation}</p>
            </div>
        </ProfileDataContainer>)
        :
        (<CreateProfileContainer>
            <div onClick={() => history.push('/createprofile')}>
                <ProfileIcon />
                <IconText>Create profile</IconText>
            </div>
    </CreateProfileContainer>)}
        </>
}

const ProfileDataContainer = styled.div`
  display: grid;
  background-color: var(--primary-10);
`

const CreateProfileContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background-color: var(--primary-10);
  box-shadow: var(--shadow-items);
  
  div {
    display: grid;
    grid-template-rows: min-content min-content;
    place-self: center stretch;
  }
`

const IconText = styled.p`
  place-self: center;
  background-color: var(--primary-10);
  font-size: 1.5em;
`

const ProfileIcon = styled(RiProfileLine)`
  place-self: center;
  font-size: 4em;
`