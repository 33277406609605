import {axiosClient} from "./AxiosClient";

const headerToken = (token) => ({
    headers: {
        Authorization: `Bearer ${token}`
    },
});

export const getRoomsByUser = (userId, token) =>
    axiosClient.get('/api/messenger/dashboard/' + userId, headerToken(token))
        .then((response) => response.data);

export const getMessagesForRoomId = (roomId, token) =>
    axiosClient.get('/api/messenger/' + roomId, headerToken(token))
        .then((response) => response.data);

export const addMessageToRoomId = (advertId, recipientId, message, token) =>
    axiosClient.post('/api/messenger',{advertId, recipientId, message}, headerToken(token))
        .then((response) => response.data);