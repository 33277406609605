import React, {useContext} from "react";
import UserAppBar from "../user/UserAppBar";
import ProfileForm from "../commons/ProfileForm";
import ProfileContext from "../context/ProfileContext";
import {useHistory} from 'react-router-dom';


export default function CreateProfile() {

    const { createProfile } = useContext(ProfileContext);
    const history = useHistory();

    return <>
        <UserAppBar />
        <ProfileForm sendSave={sendSave}/>
        </>

    function sendSave(profileFormData) {
        const {fullName, email, phoneNumber, profilePicture, currentWorkingLocation} = profileFormData;
        createProfile(fullName, email, phoneNumber, profilePicture, currentWorkingLocation);
        history.push('/userprofile');
    }
}