import React, {useEffect, useState} from "react";
import UserContext from "./UserContext";
import jwtDecode from 'jwt-decode';
import {
    loadTokenFromLocalStorage,
    loadUserDataFromLocalStorage,
    saveTokenToLocalStorage,
    saveUserDataToLocalStorage,
    deleteFromLocalStorage
} from "../service/LocalStorageService";
import {axiosClient} from "../service/AxiosClient";

export default function UserContextProvider({children}) {
    const [token, setToken] = useState(loadTokenFromLocalStorage);
    const [userData, setUserData] = useState(loadUserDataFromLocalStorage());

    useEffect(() => {
        if(token) {
            try {
                const decoded = jwtDecode(token);
                if (decoded.exp > new Date().getTime() / 1000) {
                    setUserData(decoded);
                    saveTokenToLocalStorage(token);
                    saveUserDataToLocalStorage(decoded);
                }
            } catch (e) {
                console.log(e);
            }
        }
    }, [token])

    const tokenIsValid = () =>
        token && userData.exp > new Date().getTime() / 1000;

    const loginWithUserToken = (loginData) =>
        axiosClient
            .post('/auth/login', loginData)
            .then((response) => setToken(response.data));

    const logout = () =>
        deleteFromLocalStorage();

    return <UserContext.Provider
        value={{
            token,
            logout,
            tokenIsValid,
            loginWithUserToken,
            userData
        }}>
        {children}
        </UserContext.Provider>
}