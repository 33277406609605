import React, {useContext, useState} from "react";
import AdvertsContext from "../context/AdvertsContext";
import {useParams} from 'react-router-dom';
import styled from 'styled-components/macro';
import UserAppBar from "../user/UserAppBar";
import UserContext from "../context/UserContext";
import {useHistory} from "react-router";
import Advert from "./Advert";
import MessageInput from "../commons/MessageInput";
import {addMessageToRoomId} from "../service/MessageEndpointService";

export default function AdvertDetailsPage() {

    const {advertData, remove} = useContext(AdvertsContext);
    const {userData, token} = useContext(UserContext);
    const [messageToSend, setMessageToSend] = useState("");
    const history = useHistory();
    const {id} = useParams();
    const advert = advertData.find((ad) => ad.id === id);


    return !advert ? null : (<>
        <UserAppBar />
        <Advert advert={advert} actions={userAction()}/>
    </>);

    function userAction() {
        if(userData?.sub === advert.creator) {
            return [<ButtonGroup>
                <Button key='delete' onClick={sendDelete}>Delete</Button>
                <Button key='edit' onClick={() => history.push(`/edit/${advert.id}`)}>Edit</Button>
                </ButtonGroup>];
        }
        return [<MessageInputContainer>
            <MessageInput placeholder='Your Message' onSend={onSend}
                          handleChange={(event) => setMessageToSend(event.target.value)}/>
        </MessageInputContainer>];
    }

    function sendDelete() {
        remove(id);
        history.goBack();
    }

    function onSend(event) {
        event.preventDefault();
        addMessageToRoomId(advert.id, advert.creator, messageToSend, token)
            .then((msg) => console.log(msg));
        setMessageToSend("");
        history.push('/message/dashboard');
    }
}


const Button = styled.button`
    justify-self: center;
    border: none;
    outline: none;
    background-color: var(--primary-50);
    font-size: 1.2em;
    font-weight: 600;
    padding: var(--size-m);
`

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--primary-50);
`

const MessageInputContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--primary-50);
`