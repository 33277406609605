import React from "react";
import MessageRoomCard from "./MessageRoomCard";
import styled from "styled-components/macro";
import {useHistory} from "react-router-dom";


export default function MessageRoomItems({messageRoomList, userData, advertData}) {

    const history = useHistory();

    return <>
            <MessageRoomItemContainer>
            {messageRoomList && messageRoomList.map((messageRoomInformation) =>(
                <CardsItems key={messageRoomInformation.roomId}
                                  onClick={() => history.push( `/message/${messageRoomInformation.roomId}`)}>
                    <MessageRoomCard searchedAdvertId={messageRoomInformation.roomMessages[0].advertId}
                                     advertData={advertData}
                                     userData={userData}/>
                </CardsItems>
            ))}
            </MessageRoomItemContainer>
        </>;
}


const MessageRoomItemContainer = styled.ul`
  display: grid;
  grid-auto-rows: min-content;
  gap: var(--size-s);
  padding: 0;
`

const CardsItems = styled.li`
  display: grid;
  grid-template-columns: 1fr;
  box-shadow: var(--shadow-items);
`