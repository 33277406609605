import React from "react";
import UseHomeService from "../hooks/useHomeService";
import styled from 'styled-components/macro';
import NumberFormat from "react-number-format";
import {RiMapPin2Fill} from "react-icons/ri";

export default function HomeScreenAdvertsListItem() {

    const [homeData] = UseHomeService();

    return <ListLayout>
        {homeData?.map((item) => (
            <HomeCardsItems key={item.id}>
                <Image>
                <CardImage src={item.image}/>
                </Image>
                <CardContent>
                    <LocationElement>
                        <RiMapPin2Fill />{item.location}
                    </LocationElement>
                    <AdHeader>
                        {item.title}
                    </AdHeader>
                    <AdNumberContainer>
                        <AdNumber>
                            Amount: {item.amount}
                        </AdNumber>
                        <AdNumber>
                            <NumberFormat
                                value={item.price}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'€'}
                            />
                        </AdNumber>
                    </AdNumberContainer>
                </CardContent>
            </HomeCardsItems>
        ))}
    </ListLayout>
}

const ListLayout = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  gap: var(--size-s);
  padding-bottom: var(--size-m);
`

const HomeCardsItems = styled.li`
  display: grid;
  grid-template-columns: min-content 1fr;
  list-style-type: none;
  box-shadow: var(--shadow-items);
`

const CardContent = styled.div`
  background-color: var(--primary-10);
`

const Image = styled.div`
  max-width: 100px;
  min-width: 100px;
  max-height: 100px;
`

const CardImage = styled.img`
  object-fit: cover;
  align-self: start;
  max-height: 100%;
  width: auto;
`

const LocationElement = styled.p`
  color: var(--tertiary-75);
  font-size: 0.9em;
  margin: 0;
  padding: var(--size-xs);
`

const AdHeader = styled.h3`
  color: var(--tertiary-100);
  font-size: 1.2em;
  margin: 0;
  padding: var(--size-xs);
`
const AdNumberContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: var(--size-s);
`

const AdNumber = styled.span`
  color: var(--tertiary-75);
  font-size: 0.9em;
  margin: 0;
  padding: var(--size-xs);
`