import React from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components/macro";
import {RiMapPin2Fill,
        RiTimeLine,
        RiToolsLine,
        RiUser3Line
} from "react-icons/ri";

export default function Advert({advert, actions=[], className}) {

    return <>
        <AdvertDetailPage className={className}>
            <ImageContainer>
                <DetailsImage src={advert.image}/>
            </ImageContainer>
            <DetailHeaderContainer>
                <DetailLocationElement>
                    <RiMapPin2Fill />{advert.location}
                </DetailLocationElement>
                <DetailsHeader>
                    {advert.title}
                </DetailsHeader>
                <DetailsNumberContainer>
                    <DetailsSpan>
                        Amount:
                        {advert.amount}
                    </DetailsSpan>
                    <DetailsSpan>
                        <NumberFormat
                            value={advert.price}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'€'}
                        />
                    </DetailsSpan>
                </DetailsNumberContainer>
            </DetailHeaderContainer>
            <DetailsSubItems>
                <DetailsSpan>
                    <RiTimeLine/>{Math.round((new Date().getTime() - Date.parse(advert.timeStamp))/(1000*3600*24))}
                    Days
                </DetailsSpan>
                <DetailsSpan>
                    <RiToolsLine/>{advert.category}
                </DetailsSpan>
                <DetailsSpan>
                    <RiUser3Line/>{advert.creator}
                </DetailsSpan>
            </DetailsSubItems>
            <DetailsDescription>
                <DetailsDescriptionHeader>
                    Description
                </DetailsDescriptionHeader>
                <DetailsDescriptionText>
                    {advert.description}
                </DetailsDescriptionText>
            </DetailsDescription>
            <div>{actions}</div>
        </AdvertDetailPage>
        </>
}

const AdvertDetailPage= styled.div`
  display: grid;
  grid-template-rows: 1fr min-content min-content 1fr;
  gap: var(--size-m);
  padding-left: var(--size-m);
  padding-right: var(--size-m);
  padding-bottom: var(--size-xxl);
`

const ImageContainer = styled.div`
  max-height: 280px;
  min-height: 280px;
  width: 100%;
`

const DetailsImage = styled.img`
  place-self: center;
  padding-top: 80px;
  max-width: 100%;
  height: auto;
`

const DetailHeaderContainer = styled.div`
  background-color: var(--primary-10);
`

const DetailsHeader = styled.h2`
  color: var(--tertiary-100);
  margin: 0;
  padding: var(--size-xs);
`

const DetailsNumberContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const DetailsSpan = styled.span`
  color: var(--tertiary-75);
  margin: 0;
  padding: var(--size-xs);
  justify-self: center;
  justify-content: space-between;
`

const DetailLocationElement = styled.p`
  color: var(--tertiary-75);
  font-size: 0.9em;
  margin: 0;
  padding: var(--size-xs);
`

const DetailsSubItems = styled.div`
  display: grid;
  grid-template-columns:  repeat(3, 1fr);
  background-color: var(--primary-10);
`

const DetailsDescription = styled.div`
  background-color: var(--primary-10)
`

const DetailsDescriptionHeader = styled.h3`
  color: var(--tertiary-100);
  margin: 0;
  padding: var(--size-xs);
`

const DetailsDescriptionText = styled.p`
  color: var(--tertiary-100);
  font-size: 0.9em;
  margin: 0;
  padding: var(--size-xs);
`