import {axiosClient} from "./AxiosClient";

const headerToken = (token) => ({
    headers: {
        Authorization: `Bearer ${token}`
    },
});

export const getAllAdverts = (token) =>
    axiosClient.get('/api/ads', headerToken(token)).then((response) => response.data);

export const getAdvertsForUser = (username, token) =>
    axiosClient.get('/api/ads?creator=' + username, headerToken(token)).then((response) => response.data);

export const addNewAdvert = (image, title, description, category, amount, weight, price, location, contactInformation, token) =>
    axiosClient.post('/api/ads',
            { image, title, description, category, amount, weight, price, location, contactInformation },
            headerToken(token))
        .then((response) => response.data);

export const updateAdvert = (id, image, title, description, category, amount, weight, price, location, contactInformation, token) =>
    axiosClient.put('/api/ads/' + id,
            { id, image, title, description, category, amount, weight, price, location, contactInformation },
            headerToken(token))
        .then((response) => response.data);

export const removeAdvert = (id, token) =>
    axiosClient.delete('/api/ads/' + id, headerToken(token));