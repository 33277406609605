import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import {RiLoginCircleLine} from "react-icons/ri";


export default function BottomAppBar() {
    const history = useHistory();

    return <HeaderStyled>
            <Image src='/rubble_logo.png' alt='rubble logo'/>
            <Button onClick={() => history.push('/login')}><RiLoginCircleLine /></Button>
    </HeaderStyled>;
}

const HeaderStyled = styled.nav`
  position: fixed;
  width: 100%;
  z-index: 1;
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  background-color: var(--primary-50);
  
  h2 {
  padding-left: var(--size-s);
  }
`

const Image = styled.img`
  max-height: 70px;
  padding-left: var(--size-xs);
`

const Button = styled.button`
  border: 0;
  padding-top: var(--size-s);
  padding-right: var(--size-l);
  font-size: var(--size-l);
  background-color: var(--primary-50);
`




