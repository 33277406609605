import React from "react";
import styled from 'styled-components/macro';

export default function WelcomeText() {

    return <WelcomeTextContainer>
        <h3>
        A software solution for construction managers and companies
        to help reduce and re-purpose the leftovers or so called
        rubble on construction sides.
        </h3>
        </WelcomeTextContainer>
}

const WelcomeTextContainer = styled.div`
  text-align: center;
  padding-top: 60px;
`

