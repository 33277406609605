import React, {useContext, useState} from "react";
import { useHistory } from 'react-router-dom';
import UserContext from "../context/UserContext";
import styled from 'styled-components/macro';

const emptyCredentials = {
    username: '',
    password: '',
};


export default function LoginPage() {

    const { loginWithUserToken } = useContext(UserContext);

    const [credentials, setCredentials] = useState(emptyCredentials);
    const [error, setError] = useState('');

    const history = useHistory();

    return <LoginPageContainer>
                <LoginForm onSubmit={handleSubmit}>
                    <label>
                        Username
                        <input
                            name="username"
                            value={credentials.username}
                            onChange={handleChange}
                            type="text"
                        />
                    </label>
                    <label>
                        Password
                        <input
                            name="password"
                            value={credentials.password}
                            onChange={handleChange}
                            type="password"
                        />
                    </label>
                    {error && <p>{error}</p>}
                    <button>Login</button>
                </LoginForm>
                <img src='rubble_logo.png' />
        </LoginPageContainer>


        function handleSubmit(event) {
            event.preventDefault();
            loginWithUserToken(credentials)
                .then(() => history.push('/dashboard'))
                .catch(() => setError('Unknown username or password'));
        }

        function handleChange(event) {
            setCredentials({ ...credentials, [event.target.name]: event.target.value });
        }
}

const LoginForm = styled.form`
  width: 80%;
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: var(--size-xxl);
  padding-top: 100px;
  

  input {
    display: block;
    width: 100%;
    border-radius: 15px;
    text-align: center;
    font-size: var(--size-l);
  }
  
  label{
    text-align: center;
  }

  button {
    border: none;
    outline: none;
    border-radius: var(--size-m);
    background-color: var(--primary-50);
    font-size: 2rem;
    font-weight: 800;
  }
  
  p {
    text-align: center;
  }
`;

const LoginPageContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--size-l);
  background-color: var(--primary-50);
  height: 100vh;
  
  img {
    padding: var(--size-xxl);
  }
`;