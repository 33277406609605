import React from "react";
import styled from 'styled-components/macro';
import {RiSendPlaneFill} from 'react-icons/ri';

const MessageInput = (props) => {
    return <MessageForm onSubmit={props.onSend}>
        <InputContainer>
        <input
            placeholder={props.placeholder}
            onChange={props.handleChange}
            value={props.value}
            type='message'
            name='message'
        />
        <SendIcon type='submit'/>
        </InputContainer>
        </MessageForm>;
}


export default MessageInput;

const MessageForm = styled.form`
  display: grid;
  grid-template-columns: 1fr min-content;
  padding: var(--size-s);
  
  input {
  text-align: center;
    font-size: 1.1em;
    border: 0;
    flex: 1;
    outline: none;
  }
`

const SendIcon = styled(RiSendPlaneFill)`
  padding: 0.6rem;
`

const InputContainer = styled.div`
  display: inline-flex;
  flex: 1;
  border-radius: 200px;
  overflow: hidden;
  background-color: white;
`