import React, {useState} from "react";
import styled from 'styled-components/macro';
import {useHistory} from 'react-router-dom';


const initialState = {
    title: "",
    description: "",
    category: "",
    price: "",
    amount: "",
    location: "",
    weight: "",
    image: "",
    contactInformation: "",
}

export default function AdvertForm({initialForm = initialState, sendSave}) {

    const [formData, setFormData] = useState(initialForm);
    const history = useHistory();

    return <FormPage onSubmit={handleSubmit}>
        <label>
            Title
            <Input
                name='title'
                value={formData.title}
                onChange={handleChange}
                type='text'
                required
            />
        </label>
        <label>
            Picture
            <Input
                name='image'
                value={formData.image}
                onChange={handleChange}
                type='text'
                required
            />
        </label>
        <label>
            Description
            <Textarea onChange={handleChange} name="description" rows="10" >
                {formData.description}
            </Textarea>
        </label>
        <label>
            Location
            <Input
                name='location'
                value={formData.location}
                onChange={handleChange}
                type='text'
                required
            />
        </label>
        <label>
            Category
            <Input
                name='category'
                value={formData.category}
                onChange={handleChange}
                type='text'
                required
            />
        </label>
        <label>
            Price
            <Input
                name='price'
                value={formData.price}
                onChange={handleChange}
                type='number'
                required
            />
        </label>
        <label>
            Weight
            <Input
                name='weight'
                value={formData.weight}
                onChange={handleChange}
                type='number'
                required
            />
        </label>
        <label>
            Amount
            <Input
                name='amount'
                value={formData.amount}
                onChange={handleChange}
                type='number'
                required
            />
        </label>
        <label>
            Contact
            <Textarea onChange={handleChange} name="contactInformation" rows="3">
                {formData.contactInformation}
            </Textarea>
        </label>
        <ButtonGroup>
            <Button type='button' onClick={doCancel}>Cancel</Button>
            <Button type='submit'>Save</Button>
        </ButtonGroup>
        </FormPage>;

    function doCancel() {
        history.goBack();
    }

    function handleChange(event) {
        setFormData({ ...formData, [event.target.name]: event.target.value});
    }

    function handleSubmit(event) {
        event.preventDefault();
        sendSave(formData)
    }
}

const FormPage = styled.form`
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: var(--size-s);
  padding-top: 80px;
  padding-left: var(--size-m);
  padding-right: var(--size-m);
  
  label {
    place-self: center stretch;
  }
`

const Input = styled.input`
  width: 98%;
  place-self: center stretch;
  outline: none;
`

const Textarea = styled.textarea`
  width: 98%;
  place-self: center stretch;
  outline: none;
`

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--size-m);
`

const Button = styled.button`
    border: none;
    outline: none;
    background-color: var(--secondary-50);
    font-size: 1.2em;
    font-weight: 600;
`
