import React, {useContext, useState} from "react";
import styled from "styled-components/macro";
import AdvertsListItems from "./AdvertsListItems";
import UserAppBar from "./UserAppBar";
import Searchbar from "../commons/Searchbar";
import AdvertsContext from "../context/AdvertsContext";
import {RiFilter3Fill} from "react-icons/ri";

export default function UserDashboard() {

    const { advertData } = useContext(AdvertsContext);

    const [searchedTerm, setSearchedTerm] = useState('');
    const lowerCaseSearchTerm = searchedTerm.toLowerCase();
    const filteredData = advertData.filter((term) =>
        (term.title.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (term.description.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (term.location.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (term.creator.toLowerCase().includes(lowerCaseSearchTerm))
    );

    return <>
        <UserAppBar />
        <UserDashboardContainer>
            <SearchDetails>
                <Searchbar placeholder='Search' handleChange={(event) => setSearchedTerm(event.target.value)}/>
                <Filter />
            </SearchDetails>
            <UserDashboardDetails>
                <AdvertsListItems filteredData={filteredData}/>
            </UserDashboardDetails>
        </UserDashboardContainer>
        </>;
}

const UserDashboardContainer = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr;
  padding-top: 90px;
`

const SearchDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  column-gap: var(--size-m);
  padding-left: var(--size-m);
  padding-right: var(--size-m);
`

const Filter = styled(RiFilter3Fill)`
  border: 0;
  text-decoration: none;
  font-size: var(--size-xl);
  background-color: var(--secondary-50);
`

const UserDashboardDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: var(--size-l) var(--size-m) 0 var(--size-m);
`