import {axiosClient} from "./AxiosClient";

const headerToken = (token) => ({
    headers: {
        Authorization: `Bearer ${token}`
    },
});

export const getProfileForUser = (username, token) =>
    axiosClient.get('/api/profile/' + username, headerToken(token))
        .then((response) => response.data);

export const addUserProfile = (fullName, eMail, phoneNumber, profilePicture, currentLocation, token) =>
    axiosClient.post('/api/profile', {fullName, eMail, phoneNumber, profilePicture, currentLocation}, headerToken(token))
        .then((response) => response.data);

export const updateUserProfile = (fullName, eMail, phoneNumber, profilePicture, currentLocation, token) =>
    axiosClient.put('/api/profile', {fullName, eMail, phoneNumber, profilePicture, currentLocation}, headerToken(token))
        .then((response) => response.data);

export const removeUserProfile = (token) =>
    axiosClient.delete('/api/profile', headerToken(token));