import React from "react";
import UserAppBar from "../user/UserAppBar";
import styled from "styled-components/macro";
import UserProfile from "./UserProfile";
import UserAdverts from "./UserAdverts";


export default function ProfileDashboard() {


    return <>
        <UserAppBar />
        <ProfileDashboardContainer>
            <UserProfile />
            <UserAdverts />
        </ProfileDashboardContainer>
        </>;
}

const ProfileDashboardContainer = styled.div`
  display: grid;
  grid-template-rows: minmax(150px, 250px) minmax(150px, 370px);
  gap: var(--size-m);
  padding: 90px var(--size-m) 0 var(--size-m);
`
